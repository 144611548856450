import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const MenuEN = () => {
  return (
    <Layout>
      <SEO title="Meni Konoba Fakin" lang="en" />
      <div className="content menu-page">
        <div className="naslov-container">
          <h1>Menu</h1>
          <div>
            <Link to="/menu">HR</Link>
            <Link to="/menu-it">IT</Link>
            <Link to="/menu-de">DE</Link>
            <Link to="/menu-fr">FR</Link>
            <Link to="/menu-rus">RUS</Link>
          </div>
        </div>
        <h3>Cold starters</h3>
        <ul>
          <li>Truffle cream with truffle cheese</li>
          <li>Salad with prawns, goat cheese and fresh cucumbers</li>
          <li>Baby beef tartare with aioli and toast</li>
          <li>Vitello tonnato</li>
          <li>Liver pâté with truffles and Istrian sausage</li>
          <li>Homemade prosciutto with olives</li>
          <li>
            Fakin Plate for 2 (homemade prosciutto, homemade sausages, homemade
            cheese and cheese with truffles)
          </li>
        </ul>
        <h3>Soups</h3>
        <ul>
          <li>Black truffle soup</li>
          <li>Cream of asparagus</li>
          <li>Istrian maneštra</li>
        </ul>
        <h3>Hot starters</h3>
        <ul>
          <li>Fuži pasta with black truffles</li>
          <li>Fry-up with black truffles</li>
          <li>Spaghetti with prawns, mussels and cherry tomatoes</li>
          <li>Paccheri with Bolognese sauce and fresh horseradish</li>
          <li>Roman gnocco in truffle sauce with Istrian pancetta chips</li>
          <li>Baby beef stew with homemade gnocchi</li>
        </ul>
        <h3>Main courses</h3>
        <ul>
          <li>
            Sea bass fillet gratinated with an herb crust, ratatouille and
            mashed potatoes with chives
          </li>
          <li>
            Chicken fillet stuffed with cheese and roasted in an oven, on a bed
            of spinach and champignons
          </li>
          <li>
            Lamb cutlet in thyme sauce, roast potatoes with rosemary and
            julienne vegetables
          </li>
          <li>Beefsteak with black truffles on a bed of mashed potatoes</li>
          <li>
            Beefsteak in Teran wine sauce with mashed celery and salad garnish
          </li>
          <li>
            Homemade ombolo (boneless pork cutlet) with black truffle and baked
            potatoes
          </li>
          <li>Grilled vegetables</li>
          <li>Game ragout with roasted polenta</li>
        </ul>
        <h3>Desserts</h3>
        <ul>
          <li>Chocolate soufflé</li>
          <li>Chocolate truffle cream</li>
          <li>Chocolate pralines with truffles</li>
          <li>Crepes Suzettes in orange and Cointreau sauce</li>
          <li>Crème brûlée</li>
          <li>Plate of Istrian cheeses with olive iol, honey and almonds</li>
        </ul>
        <h3>Salads</h3>
        <ul>
          <li>Salad with truffles</li>
          <li>Daily selection of seasonal salads</li>
        </ul>
      </div>
    </Layout>
  )
}

export default MenuEN
